import { EnvironmentName } from './environment-types';

export const environment = {
  name: 'cebscftesting' as EnvironmentName,
  production: true,
  isSaaSProject: true,
  rateLimiterHeader: '',
  maxUserInactivityMinutes: 15,
  paymentGraphQLUrl: 'https://payment.services.test.ceb.finverity.com/graphql',
  documentGraphQLUrl: 'https://document.services.test.ceb.finverity.com/graphql',
  notificationGraphQLUrl: 'https://notification.services.test.ceb.finverity.com/graphql',
  notificationWSGraphQLUrl: 'wss://notification.services.test.ceb.finverity.com/graphql',
  kycGraphQLUrl: 'https://kyc.services.test.ceb.finverity.com/graphql',
  actionLogGraphQlUrl: 'https://action-log.services.test.ceb.finverity.com/graphql',
  socketUrl: 'https://api.services.test.ceb.finverity.com/',
  serverUrl: 'https://api.services.test.ceb.finverity.com/',
  SSOUrl: 'https://sso.services.test.ceb.finverity.com/',
  platform: {
    title: 'Credit Europe Bank NV Admin Portal',
    company: 'Credit Europe Bank NV',
    logoUrl: 'assets/logo/logo_ceb.svg',
    saasSuffixLogoPath: 'assets/powered_by_logo.svg',
    faviconUrl: 'assets/favicon/favicon_ceb.ico',
  },
  currenciesUrl: 'https://payment.services.test.ceb.finverity.com/utils/currency-config',
  userDocumentsUrl: 'https://sso.services.test.ceb.finverity.com/utils/document-signs',
  businessProcessSettings: {
    isPayerPrepaymentActive: true,
  },
  userSnap: {
    enabled: false,
    GlobalAPIKey: '',
    ProjectAPIKey: '',
  },
  sentry: {
    enabled: false,
    dsn: '',
  },
};

export const AUTH_API_KEY = '@api-SSO';
export const THIRD_PARTY_API_KEY = '@api-third-party';
